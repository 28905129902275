<template>
    <div>
        <v-row justify='space-between'>
            <!-- <v-col cols="6">
                <h1>
                    My Course
                </h1>
            </v-col> -->
            <!-- <v-col lg="3" md="6" sm="6">
                <v-select
                v-model="termSelected"
                :items="itemsSelect"
                label="เลือกเทอม"
                id="select-term"
                solo
                ></v-select>
            </v-col> -->
        </v-row>
        <div v-if="courseLists.length > 0">
        <v-row class="pb-5">
            <v-col v-for="(course, i) in courseLists" :key="i" 	xl="3" lg="4" md="6" sm="6">
                <CourseCardStudents :regCourseObject="course" :mangoGrant="mangoGrant" :id="`course-card-${i}`"   :key="course._id" @checkLmsJob="onCheckLmsJob"  @CourseSpec="CourseSpec"></CourseCardStudents>
            </v-col>
        </v-row>
        </div>
        <div v-else>
            <v-card height="500px" class="d-flex align-center justify-center">
                <v-card-actions class="justify-center">
                    <v-spacer />
                    <h1 style="font-family: Prompt; color:#A7A6A6 ;">ท่านไม่มีวิชาเรียนในตอนนี้</h1>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        
        </div>
    </div>
</template>

<script>
import CourseCardStudents from './CourseCardStudents'

export default {
    name: 'MyCourseStudents',
    props: {
        courseLists: Array,
        currentSemester: String,
        mangoGrant: Boolean
    },
    components: {
        CourseCardStudents
    },
    data: () => ({

    }),
    created() {
    },
    computed: {
        termSelected: {
            get () {
                return {value: this.currentSemester}
            },
            set (val) {
                console.log("on change semester",val)
                this.$emit('onTermSelectedChange', val)
            }
        },
        termSelected2: function () {
            return {value: this.currentSemester}
        }
    },

    methods: {
        onCreateTeamsSubmit (sectionArr,courseid) {
            console.log("My course onCreateTeamsSubmit:",sectionArr , courseid)
            // console.log("My course onCreateTeamsSubmit val2:",courseid)
            this.$emit('createTeamsSubmit', sectionArr, courseid)
        },
        onCreateLmsSubmit (createData,courseid , service) {
            console.log("MyCourse:>",service)
            this.$emit('createLmsSubmit', createData, courseid, service)
        },
        onCreateCMUExamSubmit(sectionArr,courseid){
            // console.log("ggggg")
            this.$emit('createCMUExamSubmit', sectionArr, courseid)
        },
        onCheckTeamJob (courseId, mappingId) {
            this.$emit('checkTeamJob', courseId, mappingId)
        },
        onCheckLmsJob (webUrl) {
            this.$emit('checkLmsJob', webUrl)
        },
        onUpdateSync(courseId, status){
            // console.log("onUpdateSync", courseId, status)
            this.$emit('handleUpdateSync', courseId, status)
        },
        clickCard () {
            console.log("click card")
        },
        CourseSpec( courseId ){
            this.$emit('CourseSpec', courseId)
        }
    },
}
</script>