<template>
    <v-stepper v-model="e1">
        <v-stepper-header>
        <v-stepper-step
            :complete="e1 > 1"
            step="1"
        >
            เลือก LMS
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="e1 > 2"
            step="2"
        >
            วิชาที่จะนำมาใช้อีกครั้ง
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="e1 > 3"
            step="3"
        >
            เลือก Section
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">
            อื่นๆ
        </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
        <v-stepper-content step="1">
            <v-row class="py-3 text-center">
                <v-col>
                    <v-btn class="btn-lms-fixed-size" color="#CFEDFF" @click="validateNextStepper(1,2)">
                        <v-img
                            alt="canvas-logo"
                            src="../assets/images/canvas-logo-crop.png"
                            max-height="100"
                            max-width="100"
                        ></v-img>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn class="btn-lms-fixed-size" color="#F4EBFE" @click="validateNextStepper(1,2)">
                        <v-img
                            alt="moodle-logo"
                            src="../assets/images/moodle-logo.png"
                            max-height="100"
                            max-width="100"
                        ></v-img>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn class="btn-lms-fixed-size" color="#DAFFF1" @click="validateNextStepper(1,2)">
                        <v-img
                            alt="mango-logo"
                            src="../assets/images/mango-logo.png"
                            max-height="100"
                            max-width="100"
                        ></v-img>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(0,1)">
                    Back
                    </v-btn>
                </v-col>
                <!-- <v-spacer></v-spacer>
                <v-col class="text-right">
                    <v-btn
                    color="primary"
                    @click="e1 = 2"
                    >
                    Continue
                    </v-btn>
                </v-col> -->
            </v-row>

        </v-stepper-content>

        <v-stepper-content step="2">
            <v-row >
                <v-col cols="2"></v-col>
                <v-col align-self="center">
                    <v-select
                    :items="itemLmsCourses"
                    label="Outlined style"
                    outlined
                    ></v-select>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(1,2)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                    <v-btn
                    color="primary"
                    @click="e1 = 3"
                    >
                    Continue
                    </v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
            <v-card outlined class="mb-2">
                <v-row >
                    <v-col cols="5"></v-col>
                    <v-col align-self="center">
                        <v-checkbox
                            v-model="selected"
                            label="001"
                            value="001"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="selected"
                            label="002"
                            value="002"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="5"></v-col>
                </v-row>
            </v-card>

            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(2,3)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                    <v-btn
                    color="primary"
                    @click="e1 = 4"
                    >
                    Continue
                    </v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>

        <v-stepper-content step="4">
            <v-card
            class="mb-12"
            color="grey lighten-1"
            height="200px"
            ></v-card>

            <v-row>
                <v-col>
                    <v-btn text @click="validatePrevious(3,4)">
                    Back
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                    <v-btn
                    color="primary"
                    @click="e1 = 1"
                    >
                    Finish
                    </v-btn>
                </v-col>
            </v-row>
        </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>
<script>
    export default {
        name: 'CreateUseOldLmsForm',
        data () {
            return {
                e1: 1,
                currentStep: "lmsType",
                selected: ['001'],
                itemLmsCourses: [1,2,3,4,5]
            }
        },
        methods: {
            validateNextStepper (current, next) {
                console.log("validateNextStepper:",next)
                switch (next) {
                    case 2:
                        this.e1 = next
                        console.log("this.el:",this.e1)
                        break;
                    default:
                        break;
                }
            },
            validatePrevious (back, current) {
                switch (back) {
                    case 0:
                        this.$emit('backDialog', true)
                        break
                    case 1:
                        this.e1 = back
                        console.log(current)
                        console.log("close modal")
                        break
                    case 2:
                        this.e1 = back
                        console.log(current)
                        console.log("close modal")
                        break
                    default:
                        break
                }
            }
        }
    }
</script>