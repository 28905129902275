<template>
  <div>
    <v-dialog
      v-model="dialog"
      :width="dialogSize"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#745CA8"
            dark
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-plus</v-icon>
            Create
        </v-btn>
      </template>
      <create-new-lms-form :termSelected="termSelected" :courseData="courseData" :mangoGrant="mangoGrant" @closeDialog="dialog = false" @backDialog="onSelectCreateStyle(3)" v-show="showCreateNew" @modalSubmit="onModalSubmit" :key="refreshComponent"/>
      <create-use-old-lms-form @closeDialog="dialog = false" @backDialog="onSelectCreateStyle(3)" v-show="showUseold"/>
      <!-- <v-card v-show="showSelectView">
        <v-card-title>
            <b>Create LMS</b>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false"  >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="py-3 text-center">
            <v-col>
                <v-btn class="btn-lms-fixed-size" color="#745CA8" elevation="5" @click="onSelectCreateStyle(1)">
                    <b style="font-size:18px;color:#FFFFFF">เปิดคอร์สใหม่</b>
                </v-btn>
            </v-col>
            <v-col>
                <v-btn class="btn-lms-fixed-size" color="#FFFFFF" elevation="5" @click="onSelectCreateStyle(2)" disabled>
                    <b style="font-size:17px">ใช้งานคอร์สเดิม</b>
                </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
    </v-dialog>
  </div>
</template>

<script>
  import CreateNewLmsForm from './CreateNewLmsForm'
  import CreateUseOldLmsForm from './CreateUseOldLmsForm'
  export default {
    name: 'CreateLmsModal',
    props: {
      termSelected:String,
      courseData: {
          type: Object
      },
      openModal: Boolean,
      mangoGrant: Boolean
    },
    components: {
      CreateNewLmsForm,
      CreateUseOldLmsForm
    },
    data () {
      return {
        dialog: false,
        dialogSize: 500,
        showCreateNew: true,
        showUseold: false,
        showSelectView: true,
        refreshComponent : 0 
      }
    },
    watch: {
      openModal: function () {
        this.dialog = true
      },
      dialog: function () {
        this.$emit('modalClose', false)
      }
    },
    methods: {
      // forceRerender() {
      //   this.refreshComponent += 1;
      // },
      onSelectCreateStyle (selected) {
        switch (selected) {
          case 1:
            this.showSelectView = false
            this.dialogSize = 700
            this.showCreateNew = true
            break;
          case 2:
            this.showSelectView = false
            this.dialogSize = 700
            this.showUseold = true
            break;
          case 3:
            this.dialogSize = 600
            this.showCreateNew = true
            this.showUseold = false
            this.dialog = false
            this.showSelectView = false
            break;
          default:
            break;
        }
      },
      onModalSubmit (createData , service) {
        // console.log(createData , service)
        this.refreshComponent += 1;
        this.$emit('modalSubmit', createData ,service)
        this.dialog = false
        this.onSelectCreateStyle(3)
      }
    },
  }
</script>