<template>
    <v-dialog
      v-model="ShowAdminList"
      
      max-width="500"
    >
      
      <v-card>
        <v-card-title>
          <h3>รายชื่อผู้ดูเเลในสังกัด</h3>
        </v-card-title>
        <v-card-text>
          
            <div class="mt-5 mb-5" v-for="(detail,i) in AdminList" :key="i">
              <v-card>
                <v-card-title>
                  <h3>{{detail.prefixCourseNumber}} {{detail.title}}</h3>
                </v-card-title>
                <v-card-subtitle>
                  <div v-for="(item , j) in detail.adminList" :key="i+`-`+j">
                    {{item.email}}
                  </div>
                </v-card-subtitle>
              </v-card>
            </div>
          
        </v-card-text>
       
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose"
          >
            Close
          </v-btn>
         
        </v-card-actions>
      </v-card>
      
    </v-dialog>
</template>
<script>

export default {
  name : "ModalShowAdmin",
  data:()=>({
    headers: [
        { text: 'email'},
    ]
  }),
  props: {
      ShowAdminList : Boolean , 
      AdminList: Array
  },
  methods:{
    handleClose(){
      this.$emit('handleClose')
    }
  }
}

</script>