<template>
    <v-row justify="center">
        <v-dialog
            v-model="showModal"
            max-width="600"
            @click:outside="onClose"
        >
       
        <v-card  class="text-center" full-width>
           <!-- <v-card>
             -->
             <!-- <v-card-text class="text-h5 mt-5"></v-card-text> -->

    
            <v-col class="align-center justify-content-center" style="padding: 2.3rem;">
                <b style="font-size: 24px;">ความหมายของสีบนปุ่ม Auto Add/Drop</b>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="align-center justify-content-center" md="1">
                        <!-- <div class="text-center" style="font-size: 10px; margin-right: 17px;">Auto Add/Drop</div> -->
                        <div class="text-center tri-state-toggle">
                        <button :class="'tri-state-toggle-button-no'"  id="toggle-button3">
                        </button>
                        <button :class=" 'tri-state-toggle-button-middle '"  id="toggle-button2">
                        </button>
                        <button :class="'tri-state-toggle-button active '" id="toggle-button1">
                        </button>
                        </div>
                    </v-col>
                    <v-col class="text-left ml-5" md="10">
                        <u>เปิด</u>การนำเข้ารายชื่อนักศึกษาโดยอัตโนมัติของวิชา
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="align-center justify-content-center" md="1">
                        <!-- <div class="text-center" style="font-size: 10px; margin-right: 17px;">Auto Add/Drop</div> -->
                        <div class="text-center tri-state-toggle">
                        <button :class="'tri-state-toggle-button-no active'"  id="toggle-button3">
                        </button>
                        <button :class=" 'tri-state-toggle-button-middle'"  id="toggle-button2">
                        </button>
                        <button :class="'tri-state-toggle-button'" id="toggle-button1">
                        </button>
                        </div>
                    </v-col>
                    <v-col class="text-left ml-5" md="10">
                        <u>ปิด</u>การนำเข้ารายชื่อนักศึกษาโดยอัตโนมัติของวิชา  
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col class="align-center justify-content-center" md="1">
                        <!-- <div class="text-center" style="font-size: 10px; margin-right: 17px;">Auto Add/Drop</div> -->
                        <div class="text-center tri-state-toggle">
                        <button :class="'tri-state-toggle-button-no'"  id="toggle-button3">
                        </button>
                        <button :class=" 'tri-state-toggle-button-middle active '"  id="toggle-button2">
                        </button>
                        <button :class="'tri-state-toggle-button '" id="toggle-button1">
                        </button>
                        </div>
                    </v-col>
                    <v-col class="text-left ml-5" md="10">
                        จะเเสดงก็ต่อเมื่อภายในวิชามีการเลือกเปิดหรือปิด Auto Add/Drop <br/> ในบาง Section 
                    </v-col>
                </v-row>
            </v-col>
           
           

            <v-col>
                <v-btn
                    width="140"
                    class="white--text"
                    color="#69A5FF"
                    @click="onOpen()"
                >
                ดูข้อมูลเพิ่มเติม
                </v-btn>
            </v-col>
            <v-col>
                <v-btn
                    style="margin-top: -1.5rem ;" 
                    text
                    @click="onClose()"
                >
                ยกเลิก
                </v-btn>
                
            </v-col>

        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        name : "ModalNotifyInfo",
        props:{
            showModal : Boolean,
            openWeb : String  
        },
        methods:{
            onClose(){
                this.$emit('onClose')
            },
            onOpen(){
                window.open(this.openWeb , '_blank')
                return 0;
            }
        },
    }
</script>

