<template>
    <v-row justify="center">
        <v-dialog
        v-model="showModal"
        max-width="480"
        >
       
        <v-card >
            <v-card-text class="text-center" style="padding-top: 50px; font-size: 32px; font-weight: bolder;"><b>{{textTitle}}</b></v-card-text>
            <v-card-text class="text-center" style="padding-top: 5px; padding-bottom: 40px; font-size: 20px; ">{{textDetail}}</v-card-text>
            <v-card-text class="text-center" style="font-size: 16px; font-weight: bolder;">รายชื่ออ้างอิงจากสำนักทะเบียน <br/> โดยระบบนี้จะช้ากว่าสำนักทะเบียน 1 วัน</v-card-text>
            <v-col class="text-center">
                <v-btn
                    width="140"
                    class="white--text"
                    color="#69A5FF"
                    @click="onConfirm()"
                >
                ยืนยัน
                </v-btn>
            </v-col>
            <v-col style="padding-bottom: 2rem;" class="text-center">
                <v-btn
                    style="margin-top: -1.0rem ;" 
                    text
                    @click="onClose()"
                >
                ยกเลิก
                </v-btn>
                
            </v-col>

        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import 'vuetify/dist/vuetify.min.css'

    export default {
        name : "ModalNotifyAutoEnroll",
        props:{
            showModal : Boolean,
            textTitle : String , 
            textDetail : String , 
        },
        methods:{
            onClose(){
                this.$emit('onClose')
                return 0;
            },
            onConfirm(){
                this.$emit('onConfirm')
                return 0;
            }
        },
    }
</script>