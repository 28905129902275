<template>
  <div>
    <dashboard :courseLists="regCourses" :currentSemester="currentSemester" :mangoGrant="showMenu.mango" :showAdmin="showAdmin" @createCMUExamSubmit="onCreateCMUExamSubmit"  @createTeamsSubmit="onCreateTeamsSubmit" @createLmsSubmit="onCreateLmsSubmit" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob"  @onTermSelectedChange="onTermSelectedChange" @onUpdateSyncStatus="onUpdateSyncStatus" @CheckAdmin="CheckAdmin" v-if="!StudentsView"/>
    <DashboardStudent :courseLists="regCourses" :currentSemester="currentSemester" :mangoGrant="showMenu.mango" @checkLmsJob="onCheckLmsJobStudent" @CourseSpec="CheckCourseSpec" v-else/>
    <ModalShowAdmin :ShowAdminList="showAdmin" :AdminList="AdminList" @handleClose="handleClose" />
    <ModalNotifyInfoCreateFlow :showModal="modalCreate" :openWeb="openWeb" :sectionCompleteShow="createComplete" :sectionUncompleteShow="createUncomplete" @onClose="onClose"></ModalNotifyInfoCreateFlow>
    <WelcomeScreen  :showModal="showWelcomeScreen" @onAccept="onAccept"/>
  </div>
  
</template>
<script>
  import DashboardStudent from '../components/DashboardStudent'
  import ModalShowAdmin from '../components/ModalShowAdmin.vue'
  import Dashboard from '../components/Dashboard'
  import apiGatewayCpmService from "@/services/apiGatewayService"
  import apiLmsManagementService from "@/services/apiLmsManagementService"
  import ModalNotifyInfoCreateFlow from '../components/ModalNotification/ModalNotifyInfoCreateFlow.vue'
  import WelcomeScreen from '../components/ModalWelcomeScreen/WelcomeScreen.vue'

  const config = require(`@/config`)
  import Swal from 'sweetalert2'

  export default {
    name: 'Home',
    components: {
      Dashboard,
      ModalShowAdmin,
      DashboardStudent,
      ModalNotifyInfoCreateFlow,
      WelcomeScreen
    },
    created() {
      console.log("showWelcomeScreen) :>" , this.$cookie.get('showNotify'))
      localStorage.setItem('router', true)
      // localStorage.setItem("termSelected" , config.lmsConfig.currentSemester)
      Swal.fire({
        text: 'loading...',
        allowOutsideClick: false
      })
      Swal.showLoading()
      this.getProfileuser()

     
      // this.getCoursesOfUser(localStorage.getItem('accessToken'))
      // console.log(config.lmsConfig.mango)
    },
    computed: {
      userData: {
          get () {
            return this.$store.state.userData
          },
          set () {
            this.$store.commit('setUserData')
          }
      }
    },
    data() {
      return {
        regCourses: [],
        msTeamsList: [],
        lmsList: [],
        examList:[],
        currentSemester: config.lmsConfig.currentSemester,
        showMenu: {
          mango: false
        },
        showAdmin: false , 
        AdminList: [] , 
        StudentsView : true ,
        termSelected : localStorage.getItem('termSelected')?localStorage.getItem('termSelected'):config.lmsConfig.currentSemester, 
        modalCreate : false ,
        createComplete : [],
        createUnComplete : [], 
        openWeb : '' , 
        showWelcomeScreen :  this.$cookie.get('showNotify') === 'false'? false : true
      }
    },
    methods: {
        onAccept(){
          this.showWelcomeScreen = false
        },
        async getProfileuser(){
          const profile = await apiGatewayCpmService.getProfile(localStorage.getItem("accessToken"))
          if(profile.status){
            if(profile.jobTitle?.toLowerCase().includes('employee')){
              this.StudentsView = false;
              this.getCoursesOfUser(localStorage.getItem('accessToken'))
            }else{
              this.StudentsView = true;
              this.getCourseOfStudents(localStorage.getItem('accessToken'))
            }
             
          }
        },
        handleClose(){
          this.showAdmin = !this.showAdmin
        },
        onClose(){
          this.modalCreate = false
          Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
          })
          Swal.showLoading()
          this.getProfileuser()
        },
        async getCourseOfStudents(accessToken , semester = config.lmsConfig.currentSemester){
          const query = `semester=${semester}`
          let courselist = await apiGatewayCpmService.getUserRegCoursesOfStudent(accessToken, query)
          if(courselist){
            this.regCourses = courselist
          }
          Swal.close()
        },
        async getCoursesOfUser (accessToken, semester = this.termSelected) {
          const query = `semester=${semester}`
          let courselist = await apiGatewayCpmService.getUserRegCourses(accessToken, query)
          if(courselist){
            for(let i=0;i<courselist.length;i++){
              courselist[i].mapping = {}
              courselist[i].mapping.msteams = await apiGatewayCpmService.getCourseMappingList(courselist[i]._id,"msteams",localStorage.getItem("accessToken"))
              courselist[i].mapping.lms = await apiGatewayCpmService.getCourseMappingList(courselist[i]._id,"lms",localStorage.getItem("accessToken"))
            }
            this.regCourses = courselist
          }
          Swal.close()
        },
        onCreateTeamsSubmit (sectionArr,courseId , termSelected , typeCreate) {
          this.onModalTeamsSubmit(sectionArr,courseId , termSelected , typeCreate)
        },
        onCreateCMUExamSubmit(sectionArr,courseId , termSelected , typeCreate){
          this.onModalCMUExamSubmit(sectionArr , courseId ,termSelected , typeCreate)
        },
        async CheckCourseSpec( courseId ){
          // const query = `courseNumber=259106&year=2022&semester=1`
          const res = await apiGatewayCpmService.getCourseSpec(localStorage.getItem("accessToken") , courseId)
          if(res.status){
           
            Swal.fire({
              title: 'Course Spec',
              text: '',
              html:
                `<form method="post" action='https://cmu-grade-dev.system-on.cloud/coursespec'>
                  <input type="hidden"  name="specId" value=`+res?.data?.specId+`>
                  <input type="hidden"  name="basicAuthen" value="dGxpYzpVSlphUEBVeSFSMlROSXRj"/>
                  <input style=" border-radius: 5px; width: 40%; height: 48px; padding: 10px 15px; background: #95A3FF; color: #fff;" type="submit" value="ดูรายละเอียดวิชา"/>
                  </form>`,
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'ปิด',
            })
          }else{
            Swal.fire({
              title: 'Course Spec',
              text: 'ไม่พบรายละเอียดวิชา',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'ปิด',
            })
            
          }
        },
        onCreateLmsSubmit (createData,courseId , option , termSelected) {
          // console.log(createData,courseId)
          this.onModalLmsSubmit(createData,courseId, option , termSelected)
        },
        async CheckAdmin(){
          const query = `semester=${this.termSelected}&email=${this.userData.email}`
          const response = await apiGatewayCpmService.GetAdminList(localStorage.getItem("accessToken") , query)
          if(response.status){
            delete response.status;
            this.AdminList = response
          }
          this.showAdmin = true
        },
        async onUpdateSyncStatus(courseId,status){
          let reqBody = {
            "syncEnable" : status
          }
          const response = await apiGatewayCpmService.AllSyncCourse(localStorage.getItem("accessToken"), courseId, reqBody)
          if(response.status){
            Swal.fire({
                icon: 'success',
                title: 'เปลื่ยนสถานะซิงค์สำเร็จ',
            })
            this.getCoursesOfUser(localStorage.getItem('accessToken'))
          }
        },
        async onModalCMUExamSubmit (val, courseId , termSelected ,typeCreate) {
          console.log("onModalCMUSubmit:",val,courseId , termSelected ,  typeCreate)
            Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            let formData ={}
            if(termSelected === config.lmsConfig.currentSemester){
                formData ={
                    "courseId": courseId,
                    "termDateStart": config.lmsConfig.moodle.termStart,
                    "termDateEnd": config.lmsConfig.moodle.termEnd,
                    "sections": val
                }
            }else if(termSelected === config.lmsConfig.nextSemester){
                formData ={
                    "courseId": courseId,
                    "termDateStart": config.lmsConfig.moodleNext.termStart,
                    "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                    "sections": val
                }

            }else if(termSelected === config.lmsConfig.yearlySemester){
                formData ={
                    "courseId": courseId,
                    "termDateStart": config.lmsConfig.moodleYearly.termStart,
                    "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                    "sections": val
                }
                
            }
            let createLmsExam = []
            
            if(typeCreate === "sumAll" || val.length === 1){
              createLmsExam = await apiGatewayCpmService.CreateLmsExam(localStorage.getItem("accessToken") , formData);
              if(createLmsExam.status){
                this.createComplete = val
                this.createUncomplete = []
                this.modalCreate = true
                this.openWeb = `/courses/${courseId}/exam`
                Swal.close()
              }else{
                this.createComplete = []
                this.createUncomplete = [{"section" : val , "error" : String(createLmsExam?.message)}]
                this.modalCreate = true
                this.openWeb = `/courses/${courseId}/exam`
                Swal.close()
              }
            }else if(typeCreate === "splitAll"){
              val = val.sort()
              let _createComplete = []
              let _createUncomplete = []
              for(let i = 0 ; i < val.length ; i++){
                if(termSelected === config.lmsConfig.currentSemester){
                    formData ={
                        "courseId": courseId,
                        "termDateStart": config.lmsConfig.moodle.termStart,
                        "termDateEnd": config.lmsConfig.moodle.termEnd,
                        "sections": [val[i]]
                    }
                }else if(termSelected === config.lmsConfig.nextSemester){
                    formData ={
                        "courseId": courseId,
                        "termDateStart": config.lmsConfig.moodleNext.termStart,
                        "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                        "sections": [val[i]]
                    }

                }else if(termSelected === config.lmsConfig.yearlySemester){
                    formData ={
                        "courseId": courseId,
                        "termDateStart": config.lmsConfig.moodleYearly.termStart,
                        "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                        "sections": [val[i]]
                    }
                    
                }
                createLmsExam = await apiGatewayCpmService.CreateLmsExam(localStorage.getItem("accessToken") , formData);
                if(createLmsExam.status){
                  _createComplete.push(val[i])
                }else{
                  _createUncomplete.push({"section" : val[i] , "error" : String(createLmsExam?.message)})
                }

              }
              this.createComplete = _createComplete
              this.createUncomplete = _createUncomplete
              this.modalCreate = true
              this.openWeb = `/courses/${courseId}/exam`
              Swal.close()
            }
           
            // console.log("createLmsMoodle",createLmsMoodle)
            // if(createLmsExam.status){
            //     console.log("createLmsCompleted :>" , createLmsExam)
            //     // Swal.fire({
            //     //     icon: 'info',
            //     //     title: 'ระบบกำลังดำเนินการ',
            //     //     text: 'อาจใช้เวลาสักครู่ในการประมวลผล'
            //     // })
            //     // this.getCMUExamModule(this.$route.params.courseId, false)
            // }else{
            //     this.alertError(createLmsExam.message)
            // }


         
        },
        async updateExamActivate( courseId){
            await this.getCMUExamModule(courseId)
            const reqBody = {
                "activated": true
            }
            for(let i=0;i<this.examList.length;i++){
                if(!this.examList[i].activated){
                    let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.examList[i].courseid, this.examList[i]._id, reqBody, localStorage.getItem("accessToken"))
                    if(resUpdateActivate.status){
                        console.log(resUpdateActivate)
                    }
                }
            }
            this.getCMUExamModule(courseId)
        },
        async onModalTeamsSubmit (val, courseId , termSelected , typeCreate) {
          console.log("check :>" , val, courseId , termSelected , typeCreate)
          if(termSelected === config.lmsConfig.currentSemester || termSelected === config.lmsConfig.nextSemester || termSelected === config.lmsConfig.yearlySemester){
            let reqBody = {
                "module":"msteams",
                "service":"msteams",
                "sections": val
            }
            let createTeamsMapping = {}
            console.log("reqBody :" , reqBody)
            Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            if(typeCreate === "sumAll" || val.length === 1){
                createTeamsMapping = await apiGatewayCpmService.createCourseMapping(courseId,reqBody,localStorage.getItem("accessToken"))
                if(createTeamsMapping.status){
                    await this.updateTeamsActivate()
                    const createTeams = await this.createTeamsChannel(createTeamsMapping._id)
                    if(createTeams.status){
                      
                      this.createComplete = val
                      this.createUncomplete = []
                      this.modalCreate = true
                      this.openWeb = `/courses/${courseId}/teams`
                      Swal.close()
                    }else{
                      this.createComplete = []
                      this.createUncomplete = [{"section" : val , "error" : String(createTeams?.message)}]
                      this.modalCreate = true
                      this.openWeb = `/courses/${courseId}/teams`
                      Swal.close()
                        // this.alertError(createTeams.message)
                    }
                }else{
                    // this.alertError(createTeamsMapping.message)
                  this.createComplete = []
                  this.createUncomplete = [{"section" : val , "error" : String(createTeamsMapping?.message)}]
                  this.modalCreate = true
                  this.openWeb = `/courses/${courseId}/teams`
                  Swal.close()
                }
            }else if(typeCreate === "splitAll"){
                val = val.sort()
                // let checkStatusComplete = 0;
                let _createComplete = []
                let _createUncomplete = []
                for(let i = 0 ; i < val.length ; i++){
                    reqBody = {
                        "module":"msteams",
                        "service":"msteams",
                        "sections": [val[i]]
                    }
                    createTeamsMapping = await apiGatewayCpmService.createCourseMapping(courseId,reqBody,localStorage.getItem("accessToken"))
                    if(createTeamsMapping.status){
                      await this.updateTeamsActivate(courseId)
                      const createTeams = await this.createTeamsChannel(createTeamsMapping._id)
                      if(createTeams.status){
                        _createComplete.push(val[i])
                      }else{
                        _createUncomplete.push({"section" : val[i] , "error" : String(createTeams?.message)})
                      }
                    }else{
                      // this.alertError(createTeamsMapping.message)
                      _createUncomplete.push({"section" : val[i] , "error" : String(createTeamsMapping?.message)})
                    }
                }
                this.createComplete = _createComplete
                this.createUncomplete = _createUncomplete
                this.modalCreate = true
                this.openWeb = `/courses/${courseId}/teams`
                Swal.close()
                
            }
          }         
        },
        async onModalLmsSubmit (val,courseId , typeCreate , termSelected) {
          const createLms = await this.selectServiceCreate( typeCreate , termSelected , val)
          this.createComplete = createLms.createComplete
          this.createUncomplete = createLms.createUncomplete
          this.modalCreate = true
          this.openWeb = `/courses/${courseId}/lms`
          Swal.close()
        },

        mapTermCreate(termSelected){
          let timePack = {}
          switch(termSelected){
            case config.lmsConfig.currentSemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodle.termStart,
                "termDateEnd": config.lmsConfig.moodle.termEnd,
                "termId": config.lmsConfig.mango.termId
              }
              return timePack;
            case config.lmsConfig.nextSemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodleNext.termStart,
                "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                "termId": config.lmsConfig.mangoNext.termId
              }
              return timePack;
            case config.lmsConfig.yearlySemester:
              timePack = {
                "termDateStart" : config.lmsConfig.moodleYearly.termStart,
                "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                "termId": config.lmsConfig.mangoYearly.termId
              }
              return timePack;
            default:
              timePack = {
                "termDateStart" : config.lmsConfig.moodle.termStart,
                "termDateEnd": config.lmsConfig.moodle.termEnd,
                "termId": config.lmsConfig.mango.termId
              }
              return timePack;
          }
        },
        
        async selectServiceCreate(typeCreate , termSelected , val){
          let formData = {};
          let createLms = null
          let _createComplete = []
          let _createUncomplete = []
          const termCreate = await this.mapTermCreate(termSelected)
          Swal.fire({
              text: 'loading...',
              allowOutsideClick: false
            })
          Swal.showLoading()
          switch(typeCreate.service){
            case "mango" :
              formData ={
                  "courseId": val.courseId,
                  "termId": termCreate.termId,
                  "sections": val.section
              }
              
              if(typeCreate.option === "sumAll" && val.section.length !== 1){
                createLms = await apiGatewayCpmService.CreateLmsMango(localStorage.getItem("accessToken") , formData);
                if(createLms?.status){
                  _createComplete.push(val.section)
                }else{
                  _createUncomplete.push({"section" : val.section , "error" : String(createLms?.message)})
                }
              }else if(typeCreate.option === "splitAll" || val.section.length === 1){
                for(let i = 0 ; i < val.section.length ; i++){
                  let formData ={
                    "courseId": val.courseId,
                    "termId": termCreate.termId,
                    "sections": [val.section[i]]
                  }
                  createLms = await apiGatewayCpmService.CreateLmsMango(localStorage.getItem("accessToken") , formData);
                  if(createLms?.status){
                    _createComplete.push(val.section[i])
                  }else{
                    _createUncomplete.push({"section" : val.section[i] , "error" : String(createLms?.message)})
                  }
                }
              }
              // console.log("_createUncomplete" , _createUncomplete)
              return {
                  'status' : createLms , 
                  'createComplete' : _createComplete ,
                  'createUncomplete' : _createUncomplete
                };
            case "moodle" :
              formData ={
                  "courseId": val.courseId,
                  "termDateStart": termCreate.termDateStart,
                  "termDateEnd": termCreate.termDateEnd,
                  "sections": val.section
              }
             
              if(typeCreate.option === "sumAll" && val.section.length !== 1){
                createLms = await apiGatewayCpmService.CreateLmsMoodle(localStorage.getItem("accessToken") , formData);
                if(createLms?.status){
                  _createComplete.push(val.section)
                }else{
                  _createUncomplete.push({"section" : val.section , "error" : String(createLms?.message)})

                }
              }else if(typeCreate.option === "splitAll" || val.section.length === 1){
                for(let i = 0 ; i < val.section.length ; i++){
                  let formData ={
                    "courseId": val.courseId,
                    "termDateStart": termCreate.termDateStart,
                    "termDateEnd": termCreate.termDateEnd,
                    "sections": [String(val.section[i])]
                  }
                  createLms = await apiGatewayCpmService.CreateLmsMoodle(localStorage.getItem("accessToken") , formData);
                  if(createLms?.status){
                    _createComplete.push(val.section[i])
                  }else{
                    _createUncomplete.push({"section" : val.section[i] , "error" : String(createLms?.message)})
                  }
                }
                
              }
              return {
                  'status' : createLms , 
                  'createComplete' : _createComplete ,
                  'createUncomplete' : _createUncomplete
                };
            default:
              break;
          }

        },
       

        async updateWebUrl (courseId, mappingId, url) {
          const reqBody = {
              "webUrl": url
          }
          const resUpdateWebUrl = await apiGatewayCpmService.updateMapping(courseId, mappingId, reqBody, localStorage.getItem("accessToken"))
          return resUpdateWebUrl
        },
        async updateTeamsActivate (courseId) {
          await this.getMsTeamsModule(courseId)
          const reqBody = {
              "activated": true
          }
          for(let i=0;i<this.msTeamsList.length;i++){
              if(!this.msTeamsList[i].activated){
                  let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.msTeamsList[i].courseid, this.msTeamsList[i]._id, reqBody, localStorage.getItem("accessToken"))
                  if(resUpdateActivate.status){
                      console.log(resUpdateActivate)
                  }
              }
          }
          this.getMsTeamsModule(courseId)
        },
        async updateLmsActivate (courseId) {
          await this.getLmsModule(courseId)
          const reqBody = {
              "activated": true
          }
          for(let i=0;i<this.lmsList.length;i++){
              if(!this.lmsList[i].activated){
                  let resUpdateActivate = await apiGatewayCpmService.updateMapping(this.lmsList[i].courseid, this.lmsList[i]._id, reqBody, localStorage.getItem("accessToken"))
                  if(resUpdateActivate.status){
                      console.log(resUpdateActivate)
                  }
              }
          }
          this.getLmsModule(courseId)
        },
        async createTeamsChannel (mappingId) {
            const reqBody = {
                "mode": "createChannelByMapping",
                "mappingId": mappingId,
                "tagName": `add-student-to-teams-${mappingId}`
            }
            const resCreateChannel = await apiGatewayCpmService.createJobs(reqBody, localStorage.getItem("accessToken"))
            return resCreateChannel
        },
        alertError (errorMessage) {
          let text = ""
          switch (errorMessage) {
              case "mappingHasReadyExit":
                  text = "course already exist"
                  break
              default:
                  text = "error"
                  break
          }
          Swal.fire({
              icon: 'error',
              title: 'Create fail',
              text: `${text}`,
          })
        },
        async getMsTeamsModule (courseId) {
          console.log("get msteam")
          const msTeamsList = await apiGatewayCpmService.getCourseMappingList(courseId,"msteams",localStorage.getItem("accessToken"))
          if(msTeamsList){
            this.msTeamsList = msTeamsList
          }
        },
        async getLmsModule (courseId) {
          console.log("get lms")
          const lmsList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
          if(lmsList){
            this.lmsList = lmsList
          }
        },
        async getCMUExamModule (courseId) {
            let tmpList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
            // check  list service is exam 
            if(tmpList){
                let _tmpList = []
                for(let i=0;i<tmpList.length;i++){
                    if (tmpList[i].service === "exam"){
                        _tmpList.push(tmpList[i])
                    }
                }
                this.exam = _tmpList
            }
            
        },
        async onCheckTeamJob (courseId, mappingId) {
          Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
          })
          Swal.showLoading()
          const resMapping = await apiGatewayCpmService.getCourseMappingOne(courseId,mappingId,localStorage.getItem("accessToken"))
          if(resMapping){
            if(resMapping[0].webUrl === ""){
              Swal.fire({
                icon: 'info',
                title: 'ระบบกำลังดำเนินการ',
                text: 'อาจใช้เวลาสักครู่ในการประมวลผล',
                showCancelButton: true,
                confirmButtonText: 'ดูสถานะ',
                cancelButtonText: 'ปิด',
               
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push(`/courses/${courseId}/teams`)
                }
              })
            }else{
              window.open(`${resMapping[0].webUrl}`,'_blank')
              Swal.close()
            }
          }
        },
        onCheckLmsJobStudent(webUrl){
          if(webUrl!==''){
            window.open(`${webUrl}`,'_blank')
          }
        },
        async onCheckLmsJob (courseId, mappingId) {
          console.log(courseId, mappingId)
          Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
          })
          Swal.showLoading()
          const resMapping = await apiGatewayCpmService.getCourseMappingOne(courseId,mappingId,localStorage.getItem("accessToken"))
          if(resMapping.status && resMapping[0].webUrl !== ""){
            window.open(`${resMapping[0].webUrl}`,'_blank')
            Swal.close()
          }else{
            Swal.fire({
              icon: 'info',
              title: 'ระบบกำลังดำเนินการ',
              text: 'อาจใช้เวลาสักครู่ในการประมวลผล',
              showCancelButton: true,
              confirmButtonText: 'ดูสถานะ',
              cancelButtonText: 'ปิด',
              // didDestroy: () => {
              //   this.$router.push(`/courses/${courseId}`)
              // }
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push(`/courses/${courseId}/lms`)
              }
            })
          }
        },
        getCouseFullSemester (shortSemester) {
          let semesterArr = shortSemester.split("")
          // console.log("semesterArr:",semesterArr)
          return `${semesterArr[0]}/25${semesterArr[1]}${semesterArr[2]}`
        },
        onTermSelectedChange (semester) {
          // console.log("onTermSelectedChange",semester)
          this.termSelected = semester
          localStorage.setItem('termSelected', semester)
          Swal.showLoading()
          this.getCoursesOfUser(localStorage.getItem("accessToken"), semester)
        },
        async checkGrant(email,grantOn){
          let grantList = await apiLmsManagementService.checkGrantStatus(email)
          if(grantList){
              for(let i=0;i<grantList.length;i++){
                  if(grantList[i].grant_on === grantOn){
                      return true
                  }
              }
          }
          return false
        },
    }
  }
</script>
