<template>
    <!-- <v-row justify="center"> -->
    <div justify="center">
        <v-dialog
            v-model="showModal"
            max-width="700"    
            @click:outside="onClose"
        >
            <v-card class="text-center"  full-width  >
                
                <v-card-title style="background: #F6F2FF; font-weight: bolder; font-size: x-large;">
                    ปฏิทินวันสำคัญ Course Portal / OBE   
                    <v-spacer></v-spacer>
                    <!-- <v-btn icon @click="showModal = false" >
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-card-title>
                 
                <v-card-content>
                    <!-- <v-data-table :headers="headers"  :items="dataByTerm[0]" class="ml-5 mr-5">
                    </v-data-table> -->
                    
                    <div v-for="(item, index) in dataList" :key="index" >
                        <v-col class="text-left" style="font-weight: bolder;">
                            <v-row>
                                <v-col class="ml-3">
                                    {{item.termId}}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="text-left">
                            <v-row>
                                <v-data-table 
                                    :headers="headers"  
                                    :items="dataByTerm[index]" 
                                    class="ml-5 mr-5" 
                                    disable-pagination 
                                    hide-default-footer="true"
                                />
                                <!-- <v-col class="ml-5">
                                    <ul class="ml-5">
                                        <li v-for="event in data.termListEvent" :key="event">[{{event.dateStr}}]  - {{event.event}}</li>
                                    </ul>
                                </v-col> -->
                            </v-row>
                          
                        </v-col>
                        <v-divider class="ml-5 mr-5" style="background: #f2f2f3;"></v-divider>
                    </div>
                    
                    <v-col>
                        <v-btn
                            text
                            @click="onClose()"
                        >
                        กลับ
                        </v-btn>
                        
                    </v-col>
                </v-card-content>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name : "ModalSchedule",
        props:{
            showModal : Boolean,
            dataList : Object
        },
        data: () =>({
            headers : [
               
                {
                    text: "วันที่",
                    align: "center",
                    value: "date",
                    divider: true,
                    class: "font-weight-black blue-grey lighten-5 black--text"
                },
                {
                    text: "บริการ",
                    align: "center",
                    value: "service",
                    divider: true,
                    class: "font-weight-black blue-grey lighten-5 black--text"
                },
                {
                    text: "รายละเอียด",
                    align: "center",
                    value: "detail",
                    divider: true,
                    class: "font-weight-black blue-grey lighten-5 black--text"
                }
            ],
            dataByTerm:{}
        }),
        created(){
            
            // console.log("dateList" , this.dataList)
            let arrDataByTerm = []
            for(let i = 0 ; i < this.dataList.length ; i++ ){
                let tmpDataList = []
                for(let j = 0 ; j < this.dataList[i].termListEvent.length ; j ++){
                    let textDate = this.dataList[i].termListEvent[j].dateStr
                    let textEvent = this.dataList[i].termListEvent[j].event.split("-")[1].replace( /(<([^>]+)>)/ig, '')
                    let textService = this.dataList[i].termListEvent[j].event.split("-")[0]
                    tmpDataList.push({
                        date: textDate,
                        service: textService,
                        detail: textEvent
                    })
                    // console.log("dateList textStr" , textEvent , textDate , textService)
                }
                arrDataByTerm.push(tmpDataList)
            }
            this.dataByTerm = arrDataByTerm
            // console.log("this.dataByTerm" , this.dataByTerm , arrDataByTerm)
        },
        methods:{
            onClose(){
                this.$emit('onClose')
            },
        
        },
    }
</script>