<template>
    <v-container fluid>
      <!-- <v-row>
          <v-col>
              <v-carousel height="400">
                  <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                  >
                      <v-btn v-if="i === 999" color="#8465c2" class="white--text vertical-horizontal-center" x-large to="/activate-microsoft-teams" id="activate-teams-btn-on-banner">
                          Activate Teams
                      </v-btn>
                  </v-carousel-item>
              </v-carousel>
          </v-col>
      </v-row> -->
      <!-- <v-alert border="left" color="green" colored-border elevation="2" >
        <v-row align="center"
            justify="center" style="height: 5rem;">
            <v-col sm="2" md="4">
            </v-col>
            <v-col justify-center sm="8"  md="6">
                <v-icon  color="green">
                    mdi-bell-ring
                </v-icon>
                ตั้งแต่วันที่ 14 ธันวาคม 2565 จะมีการซิงค์รายชื่อนักศึกษาให้ <strong  class="green--text text--lighten-1">ตรงกับสำนักทะเบียน</strong >
            </v-col>
            <v-col  md="2">
            </v-col>
        </v-row>
       
    </v-alert>  -->
    <v-card style=" border-radius: 20px!important;">
        <v-tabs
            background-color="#fff"
            color="#745CA8"
            height="80px"
            active-class="active"
            >
            <v-tab href="#cmu" style="font-size: 25px; font-weight: 600; margin-left: 2rem;">CMU COURSE</v-tab>
            <!-- <v-tab href="#le" style="font-size: 25px; font-weight: 600; ">LIFELONG COURSE</v-tab> -->
            <v-tab-item value="cmu" style="border-top: 1px solid #C4C4C4 !important;" >
                <v-card>
                    <MyCourseStudents class="pt-5" :courseLists="courseLists" :currentSemester="currentSemester" @checkLmsJob="onCheckLmsJob" @CourseSpec="CourseSpec"/>
                </v-card>
            </v-tab-item>
            <!-- <v-tab-item value="le"  style="border-top: 1px solid #C4C4C4 !important;">
                <v-card>
                    <MyCourseLE  :courseLists="[]" :currentSemester="currentSemester" :mangoGrant="mangoGrant" @createTeamsSubmit="onCreateTeamsSubmit" @createLmsSubmit="onCreateLmsSubmit" @createCMUExamSubmit="onCreateCMUExamSubmit" @checkTeamJob="onCheckTeamJob" @checkLmsJob="onCheckLmsJob" @onTermSelectedChange="onTermSelectedChange" @handleUpdateSync="onHandleUpdateSync" @CheckAdmin="CheckAdmin"/>
                </v-card>
            </v-tab-item> -->
        </v-tabs>
        
    </v-card>
    </v-container>
  </template>
  
  <script>
  import MyCourseStudents from './MyCourseStudents'
  export default {
      name: 'DashboardStudent',
      props: {
          courseLists: Array,
          currentSemester: String,
          mangoGrant: Boolean
      },
      components: {
        MyCourseStudents,
      },
      data: () => ({
          items: [
              {
                  src: require('../assets/images/cpm-banner-1.png'),
              },
          ],
          regCourses: []
      }),
      created() {
      },
      methods: {
        CourseSpec(courseId){
            this.$emit('CourseSpec' ,courseId)
        },
        onCheckLmsJob (webUrl) {
            this.$emit('checkLmsJob',webUrl)
        },
      },
  }
  </script>

